import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

import Client from 'shopify-buy'

class Layout extends Component {

  state = {
    isCartOpen: false,
    cartRequest: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
  }

  componentDidMount() {
    this.Cookies = require('cookies-js')

    let checkoutId = this.Cookies.get('checkoutId')

    this.client = Client.buildClient({
      domain: "checkout.homedesignco.com.au",
      storefrontAccessToken: "1fa6386f40032d5a6d017e20e17c042f",
    })

    if (checkoutId) {
      // Find old checkout
      this.client.checkout.fetch(checkoutId).then((checkout) => {
        // Do something with the checkout
        if ( checkout.lineItems ) {
          this.setState({ checkout })
        }
      }).catch(error => {
        // Checkout expired, initialise new one
        this._initialiseCart()
      });
    } else {
      // Initialise new Checkout
      this._initialiseCart()
    }
  }

  _initialiseCart() {
    this.client.checkout.create().then((checkout) => {
      if ( checkout.lineItems ) {
        this.setState({ checkout })
        this.Cookies.set('checkoutId', checkout.id)
      }
      // Do something with the checkout

    })
  }

  _fetchProducts(query) {
    this.client.product.fetchAll().then((products) => {
      this.setState({ ...this.state, products })
    })
  }

  _addVariantToCart(variantId, quantity) {
    this.setState({ isCartOpen: true, cartRequest: true })

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]
    const checkoutId = this.state.checkout.id

    return this.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((checkout) => this.setState({ checkout, cartRequest: false }))
  }

  _updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((checkout) => this.setState({ checkout, cartRequest: false }))
  }

  _removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((checkout) => this.setState({ checkout, cartRequest: false }))
  }

  render() {
    let { children, location, staticData } = this.props
    const { checkout, isCartOpen, cartRequest, products } = this.state

    children = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        addVariantToCart: this._addVariantToCart.bind(this),
        updateQuantityInCart: this._updateQuantityInCart.bind(this),
        removeLineItemInCart: this._removeLineItemInCart.bind(this),
        fetchProducts: this._fetchProducts.bind(this),
        checkout: checkout,
        products: products,
      })
    )
    return (
      <>
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/yeb6svf.css"/>
        </Helmet>
        <Header
          pathname={location.pathname}
          isCartOpen={isCartOpen}
          setIsCartOpen={(val) => this.setState({ isCartOpen : val })}
          cartRequest={cartRequest}
          cartCount={checkout.lineItems.length}
          checkout={checkout}
          updateQuantityInCart={this._updateQuantityInCart.bind(this)}
          removeLineItemInCart={this._removeLineItemInCart.bind(this)}
          {...staticData}
        />
        <main className={checkout.lineItems.length > 0 ? 'cart-full' : ''}>
          { children }
        </main>
        <Footer {...staticData} />
      </>
    )
  }
}

export default function LayoutQuery(props) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          wp {
            siteOptions {
              settings {
                mainNav {
                  text
                  link
                }
                ourRange {
                  title
                  menu {
                    text
                    link
                  }
                }
                shopNow {
                  title
                  link
                  menu {
                    text
                    link
                  }
                }
                guides {
                  title
                  menu {
                    text
                    link
                  }
                }
                footer {
                  text
                  link
                }
              }
            }
          }
        }
      `}
      render={data => <Layout staticData={data} {...props} />}
    />
  )
}