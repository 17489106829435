import React, { Component } from 'react'
import Link from '../utils/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Cart from './cart'

import LogoBlack from '../assets/images/logo-black.svg'
import CartIcon from '../assets/images/cart.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    offCanvasDropdown: [],
    dropdown: [],
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleOffCanvasDropdown = (key) => {
    let { offCanvasDropdown } = this.state
    offCanvasDropdown[key] = !offCanvasDropdown[key]
    this.setState({ offCanvasDropdown })
  }

  _showDropdown = (key) => {
    let { dropdown } = this.state
    dropdown[key] = true
    this.setState({ dropdown })
  }

  _hideDropdown = (key) => {
    let { dropdown } = this.state
    dropdown[key] = false
    document && document.querySelector('.header__nav').classList.remove('guides-active')
    document && document.querySelector('.header__nav').classList.remove('range-active')
    this.setState({ dropdown })
  }

  _hideDropdowns = () => {
    let { dropdown } = this.state
    dropdown = []
    document && document.querySelector('.header__nav').classList.remove('guides-active')
    document && document.querySelector('.header__nav').classList.remove('range-active')
    this.setState({ dropdown })
  }

  render() {

    let { offCanvas, offCanvasDropdown, dropdown } = this.state
    let { mainNav, ourRange, shopNow, guides } = this.props.wp.siteOptions.settings
    let { 
      pathname,
      isCartOpen,
      setIsCartOpen,
      cartRequest,
      cartCount,
      checkout,
      updateQuantityInCart,
      removeLineItemInCart
    } = this.props

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let ddProps = {
      onClick: this._hideDropdowns,
      activeClassName: 'active'
    }

    const toggleIsCartExpanded = () => {
      setIsCartOpen(!isCartOpen)
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <Link to='/' title='Site Name' className='header__logo' {...props}>
              <img src={LogoBlack} alt='HDC' />
            </Link>
            { mainNav && mainNav.length > 0 &&
              <nav className='header__nav'>
                <ul>
                  { mainNav.map((el, i) => (
                    <li className={dropdown[i] ? 'active' : ''} onMouseEnter={() => this._showDropdown(i)} onMouseLeave={() => this._hideDropdown(i)} key={i}>
                      { el.link && <Link to={el.link} key={i} {...props}>{el.text}</Link> }
                      { !el.link && <span>{el.text}</span> }
                      { el.text === 'Our Range' && 
                        <div className='header__dropdown'>
                          <div className='header__inner'>
                            { ourRange.map((range, r) => (
                              <ul key={r}>
                                <li>{range.title}</li>
                                { range.menu.map((menu, m) => (
                                  <li key={m}><Link to={menu.link} key={m} {...ddProps}>{menu.text}</Link></li> 
                                )) }
                              </ul>
                            )) }
                          </div>
                        </div>
                      }
                      { el.text === 'Shop Now' && 
                        <div className='header__dropdown'>
                          <div className='header__inner'>
                            { shopNow.map((shop, s) => (
                              <ul key={s}>
                                <li><Link to={shop.link} {...ddProps}>{shop.title}</Link></li>
                                { shop.menu.map((menu, m) => (
                                  <li key={m}><Link to={menu.link} key={m} {...ddProps}>{menu.text}</Link></li> 
                                )) }
                              </ul>
                            )) }
                          </div>
                        </div>
                      }
                    </li>
                  )) }
                  <li className='cart-icon' rel='button' onClick={toggleIsCartExpanded}>
                    <img src={CartIcon} alt='Cart' />
                    { this.props.cartCount > 0 && <span className='header__cart-number'>{ this.props.cartCount }</span> }
                  </li>
                </ul>
              </nav>
            }
            <Link className='header__book btn btn--primary' to='/book-consultation/'>Book a consultation</Link>
            <div className='header__mobile-buttons'>
              <button className='cart-icon' rel='button' onClick={toggleIsCartExpanded}>
                <img src={CartIcon} alt='Cart' />
                { this.props.cartCount > 0 && <span className='header__cart-number'>{ this.props.cartCount }</span> }
              </button>
              <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                <span className='lines'></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'active' }`}>
          <div className='off-canvas__inner'>
            <div className='off-canvas__nav-wrapper'>
              <nav className='off-canvas__nav'>
                <ul>
                  <li>
                    <button type='button' onClick={()=>this._toggleOffCanvasDropdown(0)}>
                      <span>Our Range</span>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                  </li>
                  <li><Link to='/kitchen-estimator/' {...props}>Kitchen Estimator</Link></li>
                  <li>
                    <button type='button' onClick={()=>this._toggleOffCanvasDropdown(1)}>
                      <span>Shop Now</span>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                  </li>
                  <li><Link to='/how-it-works/' {...props}>How it works</Link></li>
                  <li><Link to='/why-diy-home/' {...props}>Why Home Design Co.?</Link></li>
                  <li><Link to='/guides/' {...props}>Guides</Link></li>
                </ul>
              </nav>
              <nav className={`off-canvas__nav off-canvas__nav--range ${offCanvasDropdown[0] ? 'active' : ''}`}>
                <ul>
                  <li>
                    <button type='button' onClick={()=>this._toggleOffCanvasDropdown(0)}>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <span>Our Range</span>
                    </button>
                  </li>
                  <li className='off-canvas__range'>
                    { ourRange.map((range, r) => (
                      <ul key={r}>
                        <li>{range.title}</li>
                        { range.menu.map((menu, m) => (
                          <li key={m}><Link to={menu.link} key={m} {...props}>{menu.text}</Link></li> 
                        )) }
                      </ul>
                    )) }
                  </li>
                </ul>
              </nav>
              <nav className={`off-canvas__nav off-canvas__nav--shop ${offCanvasDropdown[1] ? 'active' : ''}`}>
                <ul>
                  <li>
                    <button type='button' onClick={()=>this._toggleOffCanvasDropdown(1)}>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <span>Show Now</span>
                    </button>
                  </li>
                  <li className='off-canvas__shop'>
                    { shopNow.map((shop, s) => (
                      <ul key={s}>
                        <li><Link to={shop.link} {...props}>{shop.title}</Link></li>
                        { shop.menu.map((menu, m) => (
                          <li key={m}><Link to={menu.link} key={m} {...props}>{menu.text}</Link></li> 
                        )) }
                      </ul>
                    )) }
                  </li>
                </ul>
              </nav>
              <nav className={`off-canvas__nav off-canvas__nav--guides ${offCanvasDropdown[2] ? 'active' : ''}`}>
                <ul>
                  <li>
                    <button type='button' onClick={()=>this._toggleOffCanvasDropdown(2)}>
                      <FontAwesomeIcon icon={faAngleRight} />
                      <span>Guides</span>
                    </button>
                  </li>
                  <li className='off-canvas__guides'>
                    <ul>
                      <li>{guides?.title}</li>
                      { guides?.menu?.map((menu, m) => (
                        <li key={m}><Link to={menu.link} key={m} {...props}>{menu.text}</Link></li> 
                      )) }
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Cart
          toggleIsCartExpanded={toggleIsCartExpanded}
          isCartOpen={isCartOpen}
          cartRequest={cartRequest}
          cartCount={cartCount}
          checkout={checkout}
          updateQuantityInCart={updateQuantityInCart}
          removeLineItemInCart={removeLineItemInCart}
        />
      </>
    )
  }
}

export default Header
