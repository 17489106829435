import React from "react"
import PropTypes from "prop-types"

import Link from "../utils/link"

import ExitIcon from "../assets/images/close.svg"
import CartIcon from "../assets/images/cart.svg"
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'

const Cart = ({ toggleIsCartExpanded, isCartOpen, checkout, updateQuantityInCart }) => {
  const clickRef = React.useRef()

  const decreaseQuantity = (id, quantity) => {
    updateQuantityInCart(id, quantity - 1)
  }

  const increaseQuantity = (id, quantity) => {
    updateQuantityInCart(id, quantity + 1)
  }

  return (
    <div className={isCartOpen ? 'cart active' : 'cart'} ref={clickRef}>
      <div className="cart__head">
        <div className="cart__head__close">
          <img src={ExitIcon} onClick={toggleIsCartExpanded} alt='Close Cart' />
        </div>
      </div>
      {checkout.lineItems.length === 0 ? (
        <div className="cart__empty">
          <h4>
            <img src={CartIcon} alt='Added to your basket' />
            <span>No items in your basket</span>
          </h4>
        </div>
      ) : (
        <>
          <h4>
            <img src={CartIcon} alt='Your Basket' />
            <span>Your basket</span>
          </h4>
          <div className="cart__items">
            {checkout.lineItems.map((item, index) => {
              let imageObject =  { 
                originalSrc: item.variant.image.src, 
                width: item.variant.image.width,
                height: item.variant.image.height
              }
              const variantImage = item.variant.image && getShopifyImage({
                image: imageObject,
                width: 408,
                height: 408
              })
              return (
                <div className="cart__item" key={index}>
                  <div className="cart__image">
                    <GatsbyImage image={variantImage} alt={item.title} />
                  </div>
                  <div className="cart__details">
                    <div className="cart__item-title">{item.title} { item.variant.title !== 'Default Title' && <strong className="cart__item-variant">— {item.variant.title}</strong> }</div>
                    <div className='cart__quantity-wrap'>
                      <div className='cart__item-price'>${item.variant.price}</div>
                      <div className="cart__quantity">
                        <div className="cart__quantity-text">Qty</div>
                        <div className='cart__quantity-border'>
                          <div
                            className="cart__quantity-control"
                            onClick={() => decreaseQuantity(item.id, item.quantity)}
                          >
                            –
                          </div>
                          <div className="cart__quantity-value">
                            {item.quantity}
                          </div>
                          <div
                            className="cart__quantity-control"
                            onClick={() => increaseQuantity(item.id, item.quantity)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    <div 
                      className="cart__remove"
                      onClick={() => decreaseQuantity(item.id, 0)}
                    >
                      <img src={ExitIcon} alt='Remove item from cart' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
      {checkout.lineItems.length > 0 && (
        <div className='cart__total'>
          <div className="cart__total-wrap">
            <div className="cart__total-label">Total</div>
            <div className="cart__total-amount">${checkout.totalPrice}</div>
          </div>
          <Link to={checkout.webUrl} className="cart__checkout btn">
            Checkout
          </Link>
          <div className="cart__continue">
            <button onClick={toggleIsCartExpanded} type='button'>Continue Shopping</button>
          </div>
        </div>
      )}
    </div>
  )
}

Cart.propTypes = {
  toggleIsCartExpanded: PropTypes.func.isRequired,
  checkout: PropTypes.object.isRequired,
  updateQuantityInCart: PropTypes.func.isRequired,
}

export default Cart
