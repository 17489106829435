import React, { Component } from 'react'
import Link from '../utils/link'

import LogoWhite from '../assets/images/logo-white.svg'

class Footer extends Component {

  openGuides(){
    document && document.querySelector('.header__nav').classList.add('guides-active')
  }

  render() {

    let { ourRange, footer } = this.props.wp.siteOptions.settings

    return (
      <footer className='footer'>

        <div className='footer__inner'>
          
          <div className='footer__row'>

            <Link to='/' title='Site Name' className='footer__logo'>
              <img src={LogoWhite} alt='Home Design Co.' />
            </Link>
          
          </div>

          <div className='footer__row'>

            { ourRange.map((range, r) => (
              <div className='footer__col footer__menu' key={r}>
                <h5 className='footer__header'>{range.title}</h5>
                <ul className='footer__list'>
                  { range.menu.map((menu, i) => (
                    <li key={i}><Link to={menu.link}>{menu.text}</Link></li> 
                  )) }
                </ul>
              </div>
            )) }

            <div className='footer__col footer__menu'>

              <h5 className='footer__header'>&nbsp;</h5>

              <ul className='footer__list'>
                <li>
                  <Link to='/how-it-works/'>
                      How it works
                  </Link>
                </li>
                <li>
                  <Link to='/why-diy-home/'>
                      Why Home Design Co?
                  </Link>
                </li>
                <li>
                  <button type='button' onClick={() => this.openGuides}>Guides</button>
                </li>
              </ul>

            </div>

            <div className='footer__col'>

              <div className='footer__getting_started'>
                
                <h5 className='footer__header'>Get started</h5>

                <Link className='footer__book btn btn--primary' to='/book-consultation/'>Book a consultation</Link>

              </div>

            </div>

          </div>

          <div className='footer__row footer__row--last'>

              <div className="footer__menu">

                <ul className='footer__list'>
                  { footer.map((menu, i) => (
                    <li key={i}><Link to={menu.link}>{menu.text}</Link></li> 
                  )) }
                </ul>

              </div>

          </div>

        </div>

      </footer>
    )
  }
}

export default Footer

