exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-range-js": () => import("./../../../src/templates/range.js" /* webpackChunkName: "component---src-templates-range-js" */)
}

